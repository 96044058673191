import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
    Button,
    Stack,
    Typography,
    Card,
    CardContent,
    CardActions,
    CardMedia,
    Grid,
    CircularProgress,
} from "@mui/joy";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ip } from "../../ip.js";

export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    //donation amounts
    const donationInput = props.donationInput;
    const donation_amount = props.donation_amount;
    //organization info of the donator
    const organization = props.organization;
    //functions
    const Success_Message = props.Success_Message;
    const Error_Message = props.Error_Message;
    //email function
    const email_donator = props.email_donator;
    const admin = props.admin;
    const user = props.user;
    const status = props.status;
    const content = props.content;
    const tenant = props.tenant;
    const add_fees = props.add_fees;
    const changeStatus = props?.changeStatus;

    //guest info or donating from home page
    const email = props.email;
    const guest_name = props.guest_name;

    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!donationInput && !donation_amount) {
            Error_Message("Please enter a donation amount");
            return;
        }

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsProcessing(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {},
            redirect: "if_required",
        });

        if (error?.type === "card_error" || error?.type === "validation_error") {
            setMessage(error.message);
            return;
        }
        changeStatus();
        //push to donations table
        const donation = {
            amount: donationInput ? donationInput : donation_amount,
            name: user?.fullName || guest_name,
            email: user?.email || email,
            receiver: organization || tenant,
            tenant: tenant,
        };
        axios.post(`${ip}/addDonation`, {
          amount: donation.amount,
          name: donation.name,
          email: donation.email,
          receiver: donation.receiver,
          tenant: donation.tenant,
        }).then((res) => {
            console.log(res);
        });
        //Email(admin?.email, user?.email, user?.fullName, user?.organization, content, status, null, donationInput);
        navigate(`/${tenant}/completion`, { replace: true });

        setIsProcessing(false);
    };

    return (
      <Stack spacing={2} alignItems={"center"}>
        <PaymentElement id="payment-element" />
        <Button
          onClick={handleSubmit}
          disabled={isProcessing || !stripe || !elements}
          id="submit"
          size="md"
          sx={{ width: "300px" }}
        >
          {isProcessing ? <>Processing ...  {" "} <CircularProgress /></> : `Pay $${donationInput ? add_fees(donationInput) : add_fees(donation_amount)}`}
        </Button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </Stack>
    );
}
