import { React, useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import ReactGA from "react-ga";
import { ip } from "../ip";
import {
  Box,
  Divider,
  FormLabel,
  Stack,
  Typography,
  Card,
  Button,
  Input,
  CircularProgress,
} from "@mui/joy/";

function Contact(props) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [organization, setOrganization] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const Success_Message = props.Success_Message;
  const Error_Message = props.Error_Message;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    axios.defaults.withCredentials = true;
    if (phoneNumber.length === 12) {
      if (error === "phone") {
        setError("");
      }
    }
    if (email.includes("@") && email.includes(".")) {
      if (error === "email") {
        setError("");
      }
    }
    if (name !== "") {
      if (error === "name") {
        setError("");
      }
    }
    if (organization !== "") {
      if (error === "organization") {
        setError("");
      }
    }
  }, [email, name, organization, phoneNumber]);

  const handleSubmit = (e) => {
    if (
      email === "" ||
      name === "" ||
      organization === "" ||
      phoneNumber === ""
    ) {
      if (email === "") {
        setError("email");
      } else if (name === "") {
        setError("name");
      } else if (organization === "") {
        setError("organization");
      } else {
        setError("phone");
      }
      Error_Message("Please Fill Out All Fields!");
    } else if (!email.includes("@") || !email.includes(".")) {
      setError("email");
      Error_Message("Please Enter A Valid Email!");
    } else if (phoneNumber.length === 10) {
      setError("phone");
      Error_Message("Please Add Dashes To Your Phone Number!");
    } else if (phoneNumber.length !== 12) {
      setError("phone");
      Error_Message("Please Enter A Valid Phone Number!");
    }
    else if (phoneNumber.length === 12) {
      //check if indexes except 3 and 7 are numbers and check if indexes 3 and 7 are dashes
      if (
        isNaN(phoneNumber[0]) ||
        isNaN(phoneNumber[1]) ||
        isNaN(phoneNumber[2]) ||
        isNaN(phoneNumber[4]) ||
        isNaN(phoneNumber[5]) ||
        isNaN(phoneNumber[6]) ||
        isNaN(phoneNumber[8]) ||
        isNaN(phoneNumber[9]) ||
        phoneNumber[3] !== "-" ||
        isNaN(phoneNumber[10]) ||
        isNaN(phoneNumber[11]) ||
        phoneNumber[7] !== "-"
      ) {
        setError("phone");
        Error_Message("Please Enter a Valid Phone Number!");
      } else {
        setError("");
        setSubmitting(true);
        axios
          .post(`${ip}/addcontact`, {
            email: email,
            name: name,
            organization: organization,
            phoneNumber: phoneNumber,
          })
          .then((response) => {
            if (response.data.message === "Contact Added!") {
              Success_Message("Contact Received, Thank You!");
            }
          })
          .then(() => {
            setTimeout(() => {
              setEmail("");
              setName("");
              setOrganization("");
              setPhoneNumber("");
            }, 1000);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    }
  };

  return (
    <Box sx={{ px: { xs: 2, md: 6 } }}>
      <ToastContainer />
      <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
        Contact Us
      </Typography>
      <Typography level="body" component="p" sx={{ mb: 2, maxWidth: 600 }}>
        Interested in partnering with us and being a Corporate Gift Giver? Want
        to support your community? Let us know by submitting the following
        information. We will reach out to you and discuss next steps.
      </Typography>
      <Stack
        spacing={4}
        sx={{
          display: "flex",
          maxWidth: "800px",
          mx: "auto",
          alignContent: "left",
          justifyContent: "left",
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
          marginLeft: { xs: 0, md: -6 },
        }}
      >
        <Card>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-lg">Contact Info</Typography>
          </Box>
          <Divider />
          <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <Stack spacing={1}>
              <FormLabel>
                <b>Name</b>
              </FormLabel>
              <Input
                type="Name"
                placeholder="John Doe"
                sx={{ maxWidth: "400px" }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={error === "name"}
              />
            </Stack>
          </Stack>
          <Divider orientation="horizontal" />
          <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <Stack spacing={1}>
              <FormLabel>
                <b>Email</b>
              </FormLabel>
              <Input
                type="email"
                placeholder="example@email.com"
                sx={{ maxWidth: "400px" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={error === "email"}
              />
            </Stack>
          </Stack>
          <Divider orientation="horizontal" />
          <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <Stack spacing={1}>
              <FormLabel>
                <b>Organization</b>
              </FormLabel>
              <Input
                type="organization"
                placeholder="Org Name"
                sx={{ maxWidth: "400px" }}
                value={organization}
                onChange={(e) => setOrganization(e.target.value)}
                error={error === "organization"}
              />
            </Stack>
          </Stack>
          <Divider orientation="horizontal" />
          <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <Stack spacing={1}>
              <FormLabel>
                <b>Phone Number</b>
              </FormLabel>
              <Input
                type="phone"
                placeholder="123-456-7890"
                sx={{ maxWidth: "400px" }}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                error={error === "phone"}
              />
            </Stack>
          </Stack>
          <Button
            color="success"
            sx={{ maxWidth: "200px" }}
            onClick={() => {
              handleSubmit();
            }}
            disabled={submitting}
          >
            {submitting ? <CircularProgress /> : "Submit"}
          </Button>
        </Card>
      </Stack>
    </Box>
  );
}

export default Contact;
