import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Button,
    Grid,
    Box,
    Stack,
} from "@mui/joy";
import HomeIcon from "@mui/icons-material/Home";

function Completion(props) {
    const user = props.user;
    const tenant = props.tenant;
    const navigate = useNavigate();

    console.log(user);
    return (
        <Box height={'auto'}>
            <Stack alignItems={'center'} direction={'row'} justifyContent={'center'}>
                <Typography level="h4">Thank you for your donation🎉 Click the icon to return To home <HomeIcon color="primary" sx={{cursor: 'pointer'}} onClick={() => {
                    if (user && user?.tenant) {
                        navigate(`/${user?.tenant}/home`, { replace: true });
                    }
                    else {
                        navigate(`/${tenant}`, { replace: true });
                    }
                    
                }}/>
                </Typography>
            </Stack>
        </Box>
    );
}

export default Completion;
