import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Box,
    Input,
    Typography,
    Button,
    Stack,
    Table,
    Modal,
    ModalClose,
    ModalDialog,
    CircularProgress,
    FormLabel,
} from "@mui/joy";
import axios from "axios";
import { ip } from "../../ip";
import { isMobile } from "react-device-detect";
import Payment from "./Payment";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import emailjs from "@emailjs/browser";

function GuestCheckout(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const tenant = props.tenant;
    const Success_Message = props.Success_Message;

    // State variables
    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [organization, setOrganization] = useState("");
    const [openModal, setOpenModal] = useState(null);
    const [guest_id, setGuestId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingStripe, setLoadingStripe] = useState(false);
    const [checkedItems, setCheckedItems] = useState(location?.state?.checkedItems);
    const [itemsForEmail, setItemsForEmail] = useState(null);
    const estimatedPrice = location?.state?.estimatedPrice;
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");

    const generateTableRows = (products) => {
        //generate table rows for email, center align text
        return products
        .map((product) => {
            return `
            <tr>
            <td>${product.id}</td>
            <td>${product.product_name}</td>
            <td>${product.link || "N/A"}</td>
            <td>${product.requested_quantity}</td>
            </tr>
        `;
        })
        .join("");
    };

    function email_guest() {
        //send email with info from calling generateTableRows
        emailjs
        .send(
            "service_f5sai5q",
            "template_esovp8b",
            {
            to_email: email,
            guest_name: fullname,
            product_rows: itemsForEmail,
            },
            "x7LM43rlkJsqov2r7"
        )
        .then((response) => {
            if (response.status === 200) {
                console.log("Email sent to guest");
            } else {
                console.log("Email failed to send");
            }
        });
    }

    function add_fees(amount) {
        return (amount * 1.03 + 0.3).toFixed(2);
    }

    // useEffect(() => {
    //   axios.get(`${ip}/config-live`).then((r) => {
    //     const publishableKey = r.data["publishableKey"];
    //     setStripePromise(loadStripe(publishableKey));
    //   });

    // }, []);

    // useEffect(() => {
    //     if (!email || !fullname || !phone) return;
    //     setLoadingStripe(true);
    //     setTimeout(() => {
    //       axios
    //         .post(`${ip}/create-payment-intent-live`, {
    //           method: "POST",
    //           amount: add_fees(estimatedPrice),
    //           email: email,
    //         })
    //         .then((result) => {
    //           var clientSecret = result.data["clientSecret"];
    //           setClientSecret(clientSecret);
    //           setLoadingStripe(false);
    //         });
    //     }, 3000);
    // }, [email, fullname, phone]);

    function validate_email(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    // Handler for form submission
    const handleSubmit = () => {
        //add guest to guest table
        axios
        .post(`${ip}/guest/addGuest`, {
            name: fullname,
            email: email,
            phoneNumber: phone,
            organization: organization !== "" ? organization : null,
            tenant: tenant,
        })
        .then((response) => {
            if (response.data.id) {
            setGuestId(response.data.id);
            axios
                .post(`${ip}/guest/changeStatus`, {
                    guest_id: response.data.id,
                    checkedItems: checkedItems,
                    tenant: tenant,
                })
                .then((response) => {
                    if (response.data.message === "Accepted by Guest") {
                        //set timeout for a second, then navigate to home
                        Success_Message(
                        "Success! An email has been sent to you with the details of your order."
                        );
                        email_guest();
                        setTimeout(() => {
                            setLoading(false);
                            navigate(`/${tenant}`);
                        }, 3000);
                    }
                });
            } else {
            //get id of guest
                axios.get(`${ip}/guest/getGuestId/${email}`).then((response) => {
                    setGuestId(response.data.id);
                    axios
                    .post(`${ip}/guest/changeStatus`, {
                        guest_id: response.data.id,
                        checkedItems: checkedItems,
                        tenant: tenant,
                    })
                    .then((response) => {
                        if (response.data.message === "Accepted by Guest") {
                            //set timeout for a second, then navigate to home
                            Success_Message(
                                "Success! An email has been sent to you with the details of your order."
                            );
                            email_guest();
                            setTimeout(() => {
                                setLoading(false);
                                navigate(`/${tenant}`);
                            }, 3000);
                        }
                    });
                });
            }
        })
    };

    useEffect(() => {
        setItemsForEmail(generateTableRows(checkedItems));
    }, []);

    return (
        <Box
            sx={{
                width: isMobile ? "90%" : "50%",
                margin: "0 auto",
                padding: isMobile ? "16px" : "32px",
                border: "1px solid",
                borderColor: "neutral.outlinedBorder",
                borderRadius: "md",
                boxShadow: "sm",
                backgroundColor: "background.body",
            }}
        >
            <ToastContainer />
            <Typography
                level="h4"
                textAlign="center"
                sx={{ marginBottom: isMobile ? "16px" : "24px" }}
            >
                Guest Checkout
            </Typography>
            <Stack
                spacing={isMobile ? 2 : 3}
                sx={{
                width: "100%",
                }}
            >
                <Typography>
                <i>
                    * By clicking "Checkout", You are assuming responsibility for
                    purchasing these items and bringing them to the Detroit Autoshow *
                </i>
                </Typography>
                <Stack spacing={1}>
                <FormLabel>Full Name *</FormLabel>
                <Input
                    label="Full Name"
                    placeholder="Enter your full name"
                    required
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                    sx={{ fontSize: isMobile ? "14px" : "16px" }}
                    error={!fullname}
                />
                </Stack>
                <Stack spacing={1}>
                <FormLabel>Email *</FormLabel>
                <Input
                    label="Email"
                    type="email"
                    placeholder="Enter your email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ fontSize: isMobile ? "14px" : "16px" }}
                    error={!email || !validate_email(email)}
                />
                </Stack>
                <Stack spacing={1}>
                <FormLabel>Phone *</FormLabel>
                <Input
                    label="Phone"
                    type="tel"
                    placeholder="Enter your phone number"
                    required
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    sx={{ fontSize: isMobile ? "14px" : "16px" }}
                    error={!phone}
                />
                </Stack>
                <Stack spacing={1}>
                <FormLabel>Organization</FormLabel>
                <Input
                    label="Organization"
                    placeholder="Enter your company or org (optional)"
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                    sx={{ fontSize: isMobile ? "14px" : "16px" }}
                    required={false}
                />
                </Stack>
                <Table sx={{textAlign: 'left'}}>
                <thead>
                    <tr>
                    <th style={{ width: '30%' }}>Item</th>
                    <th>Quantity</th>
                    <th>Link</th>
                    </tr>
                </thead>
                <tbody>
                    {checkedItems.map((item) => (
                    <tr key={item.id}>
                        <td>{item.product_name}</td>
                        <td>{item.requested_quantity}</td>
                        {item.link ? (
                        <td>
                            <Button
                            variant="solid"
                            color="primary"
                            component="a"
                            href={item.link}
                            target="_blank"
                            sx={{ width: isMobile ? "100%" : "auto" }}
                            >
                            Click Here
                            </Button>
                        </td>
                        ) : (
                        <td>No Link Available</td>
                        )}
                    </tr>
                    ))}
                </tbody>
                </Table>
                {/* <Typography level="body-lg">
                        Total (After Stripe Fees): <b>${add_fees(estimatedPrice)}</b>
                    </Typography>
                    {loadingStripe ? (
                        <CircularProgress />
                    ) : clientSecret && stripePromise ? (
                        <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <CheckoutForm
                            organization={organization}
                            Success_Message={props.Success_Message}
                            Error_Message={props.Error_Message}
                            donation_amount={estimatedPrice}
                            content={checkedItems}
                            status={"Donation"}
                            ask_id={null}
                            add_fees={add_fees}
                            email={email}
                            tenant={tenant}
                            changeStatus={handleSubmit}
                            guest_name={fullname}
                        />
                        </Elements>
                    ) : (
                        <Typography>
                        Enter information above to see payment details
                        </Typography>
                    )} */}
                <Button
                type="submit"
                variant="solid"
                color="primary"
                size={isMobile ? "sm" : "md"}
                sx={{
                    marginTop: isMobile ? "16px" : "24px",
                    padding: isMobile ? "8px 16px" : "12px 24px",
                }}
                onClick={() => {
                    if (fullname && email && phone) {
                    setOpenModal(true);
                    }
                }}
                >
                {loading ? <CircularProgress /> : "Checkout"}
                </Button>
                <Modal open={openModal === true}>
                <ModalDialog>
                    <Typography
                    sx={{ justifyContent: "center", alignItems: "flex-start" }}
                    level="body-lg"
                    >
                    Are you sure you want to accept?
                    </Typography>
                    <Stack spacing={2} direction="row" justifyContent={"center"}>
                    <Button
                        color="success"
                        onClick={() => {
                            setOpenModal(false);
                            setLoading(true);
                            // checkout and return to home
                            handleSubmit();
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        color="danger"
                        onClick={() => {
                        // close modal
                        setOpenModal(false);
                        }}
                    >
                        No
                    </Button>
                    </Stack>
                </ModalDialog>
                </Modal>
            </Stack>
        </Box>
    );
}

export default GuestCheckout;
