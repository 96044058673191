import React from "react";
import Table from "@mui/joy/Table";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  Stack,
  Modal,
  ModalDialog,
  ModalClose,
  Button,
  Typography,
  List,
  ListItem,
  Checkbox,
  Input,
  Autocomplete,
  Card,
  CardCover,
  Avatar,
  LinearProgress,
} from "@mui/joy";
import emailjs from "@emailjs/browser";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { ip } from "../../ip";

function Product(props) {
  axios.defaults.withCredentials = true;
  const user = props.user;
  const guest = props.guest;
  const isAuthenticated = props.isAuthenticated;
  const setCheckedStates = props.setCheckedStates;
  const [modalOpen, setModalOpen] = useState(
    Array(props.data.length).fill(false)
  );
  const checked = props.checked;
  const askIndex = props.askIndex;
  const handleCheckBoxChange = props.handleCheckBoxChange;
  const edit = props.edit;
  const newQuantity = props.newQuantity ? props.newQuantity : [];
  const newLink = props.newLink ? props.newLink : [];
  const setNewQuantity = props.setNewQuantity ? props.setNewQuantity : () => {};
  const setNewLink = props.setNewLink ? props.setNewLink : () => {};
  const setProductNames = props.setProductNames
    ? props.setProductNames
    : () => {};
  const setNewProduct = props.setNewProduct ? props.setNewProduct : () => {};
  const tracking = props.tracking;
  const setTracking = props.setTracking;
  const isMobile = props.isMobile;
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const accepted_page_status = props.status;

  const statusText = {
    1: "Accepted",
    2: "Awaiting School Confirmation",
    3: "Completed",
  };

  useEffect(() => {
    axios.get(`${ip}/products`).then((response) => {
      setProducts(response.data);
    });
  }, []);

  useEffect(() => {
    //loop through the data and set the newQuantity state to the quantity of each item
    let temp = [];
    let tempLink = [];
    let tempProductNames = [];
    props.data.map((item) => {
      temp.push(item.quantity);
      tempLink.push(item.link);
      tempProductNames.push(item.product_name);
    });
    setNewQuantity(temp);

    //loop through the data and set the newLink state to the link of each item
    setNewLink(tempLink);

    //loop through the data and set the productNames state to the product_name of each item
    setProductNames(() => {
      const newState = [];
      props.data.map((item) => {
        newState.push(item.product_name);
      });
      return newState;
    });

    //loop through the data and set the newProduct state to the product_name of each item
    setNewProduct(() => {
      const newState = [];
      props.data.map((item) => {
        newState.push(item.product_name);
      });
      return newState;
    });
  }, [props.data]);

  function EmailNPO(
    to_email_id,
    from_email,
    from_name,
    from_organization,
    content,
    status,
    ask_id
  ) {
    axios.get(`${ip}/email/${to_email_id}`).then((response) => {
      if (response.data) {
        emailjs
          .send(
            "service_f5sai5q",
            "template_1srk5gx",
            {
              to_email: response.data.email,
              from_email: from_email,
              from_name: from_name,
              from_organization: from_organization,
              content: content,
              status: status,
              ask_id: ask_id,
            },
            "x7LM43rlkJsqov2r7"
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("email sent");
            } else {
              console.log("email not sent");
            }
          });
      }
    });
  }

  function Email(
    to_email_id,
    from_email,
    from_name,
    from_organization,
    content,
    status,
    ask_id
  ) {
    axios.get(`${ip}/email/${to_email_id}`).then((response) => {
      if (response.data) {
        emailjs
          .send(
            "service_f5sai5q",
            "template_bs16y39",
            {
              to_email: response.data.email,
              from_email: from_email,
              from_name: from_name,
              from_organization: from_organization || "N/A",
              content: content,
              status: status,
              ask_id: ask_id,
            },
            "x7LM43rlkJsqov2r7"
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("email sent");
            } else {
              console.log("email not sent");
            }
          });
      }
    });
  }

  //school side
  // function deleteAskProduct(id, product_name) {
  //   axios
  //     .post(`${ip}/asks/deleteAskProduct/${id}/${product_name}`)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         props.setStatusClick(!props.statusClick);
  //       }
  //     });
  // }

  function show_status_button(item, user, index) {
    if (user.account_type === "School" || user.account_type === "Admin") {
      if (item.acceptance_data[0] === null) {
        return (
          <Typography color="neutral" level={isMobile ? "body-sm" : "body-md"}>
            <b>Not Accepted</b>
          </Typography>
        );
      } else {
        //if the item is accepted, accepted text with link to <Ask> page
        return (
          <Stack spacing={1}>
            <Button
              color="success"
              onClick={() => {
                navigate(`/${user.tenant}/ask/${item.ask_id}`);
              }}
              size={isMobile ? "sm" : "md"}
              sx={
                isMobile
                  ? { cursor: "pointer" }
                  : {
                      cursor: "pointer",
                      width: "auto",
                      height: "auto",
                      alignContent: "center",
                      maxWidth: "200px",
                    }
              }
            >
              <b>See Details</b>
            </Button>
          </Stack>
        );
      }
    }
    //NPO side
    else {
      if (item.status && item?.status !== 0 && item.accepted_id !== null) {
        const currentStatus = statusText[parseInt(item.status)];
        return (
          <Stack spacing={1}>
            <Typography
              color="success"
              level={isMobile ? "body-sm" : "body-md"}
            >
              <b>{currentStatus || "Unknown Status"}</b>
            </Typography>
            {accepted_page_status === 4 && (
              <LinearProgress
                determinate
                size="sm"
                color="success"
                value={Math.min(parseInt((item.status + 1) * 25), 100)}
                sx={{
                  bgcolor: "background.level3",
                  maxWidth: "400px",
                }}
                variant="outlined"
              />
            )}
          </Stack>
        );
      } else {
        return (
          <Typography color="danger" level={isMobile ? "body-sm" : "body-md"}>
            <b>Not Accepted</b>
          </Typography>
        );
      }
    }
  }

  function show_error_empty_quantity(product_index) {
    //check if the requested_quantity is empty
    if (
      checked[product_index]?.id != null &&
      (!checked[product_index]?.requested_quantity ||
        checked[product_index]?.requested_quantity === undefined ||
        checked[product_index]?.requested_quantity === null ||
        isNaN(checked[product_index]?.requested_quantity) ||
        checked[product_index]?.requested_quantity < 1 ||
        checked[product_index]?.requested_quantity >
          checked[product_index]?.remaining_quantity)
    ) {
      return true;
    } else {
      return false;
    }
  }

  function show_contact_button(item, user, index) {
    if (user.account_type === "School") {
      return;
    } else {
      if (item.status && item?.status !== 0 && item.accepted_id !== null) {
        return (
          <>
            <Button
              variant="solid"
              color="primary"
              sx={{ width: "auto" }}
              onClick={() =>
                setModalOpen((prev) => {
                  const newState = [...prev];
                  newState[index] = true;
                  return newState;
                })
              }
            >
              Show
            </Button>
            <Modal
              aria-labelledby="modal-title"
              aria-describedby="modal-desc"
              open={modalOpen[index]}
              onClose={() =>
                setModalOpen((prev) => {
                  const newState = [...prev];
                  newState[index] = false;
                  return newState;
                })
              }
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ModalDialog>
                <ModalClose />
                <Typography
                  component="h2"
                  id="modal-title"
                  level="h4"
                  textColor="inherit"
                  fontWeight="lg"
                  mb={1}
                >
                  Contact Info
                </Typography>
                <Typography id="modal-desc" textColor="text.tertiary">
                  {user.account_type === "School" && item.accepted_user_name ? (
                    <List aria-labelledby="decorated-list-demo">
                      <ListItem>
                        <b>Name: </b> {item?.accepted_user_name || "N/A"}
                      </ListItem>
                      <ListItem>
                        <b>Email: </b> {item?.accepted_user_email || "N/A"}
                      </ListItem>
                      <ListItem>
                        <b>Organization: </b>{" "}
                        {item?.accepted_user_organization || "N/A"}
                      </ListItem>
                      {item.tracking ? (
                        <ListItem>
                          <b>Tracking Number: </b>{" "}
                          {<a href={item.tracking}>{item.tracking}</a> || "N/A"}
                        </ListItem>
                      ) : (
                        <ListItem>
                          <b>Tracking Number: </b> N/A
                        </ListItem>
                      )}
                      <ListItem>
                        <b>{isMobile ? "Qty" : "Quantity"} Accepted:</b>
                        {item.accepted_quantity}
                      </ListItem>
                    </List>
                  ) : item.created_user ? (
                    <List aria-labelledby="decorated-list-demo">
                      <ListItem>
                        <b>Name: </b> {item?.created_user?.fullName || "N/A"}
                      </ListItem>
                      <ListItem>
                        <b>Email: </b> {item?.created_user?.email || "N/A"}
                      </ListItem>
                      <ListItem>
                        <b>Organization: </b>{" "}
                        {item?.created_user?.organization || "N/A"}
                      </ListItem>
                    </List>
                  ) : null}
                </Typography>
              </ModalDialog>
            </Modal>
          </>
        );
      } else if (item.status === 3 && item.accepted_id === null) {
        //anonymous doner
        return (
          <>
            <Button
              variant="outlined"
              sx={{ width: "auto" }}
              color="neutral"
              onClick={() =>
                setModalOpen((prev) => {
                  const newState = [...prev];
                  newState[index] = true;
                  return newState;
                })
              }
            >
              Show
            </Button>
            <Modal
              aria-labelledby="modal-title"
              aria-describedby="modal-desc"
              open={modalOpen[index]}
              onClose={() =>
                setModalOpen((prev) => {
                  const newState = [...prev];
                  newState[index] = false;
                  return newState;
                })
              }
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ModalDialog>
                <ModalClose />
                <Typography
                  component="h2"
                  id="modal-title"
                  level="h4"
                  textColor="inherit"
                  fontWeight="lg"
                  mb={1}
                >
                  Contact Info
                </Typography>
                <Typography id="modal-desc" textColor="text.tertiary">
                  Anonymous Doner
                </Typography>
              </ModalDialog>
            </Modal>
          </>
        );
      } else {
        return (
          <Button size="sm" disabled sx={{ width: "auto" }}>
            N/A
          </Button>
        );
      }
    }
  }

  //WIP
  function show_image(item) {
    Object.keys(products).map((productId) => {
      if (
        products[productId].productName == item["product_name"] &&
        products[productId].image_url
      ) {
        return (
          <Avatar
            src={products[productId].image_url}
            alt={products[productId].productName}
          />
        );
      } else {
        return null;
      }
    });
  }

  return (
    <Table
      aria-label="optimized table"
      variant="outlined"
      hoverRow
      sx={{
        alignContent: "center",
        justifyContent: "center",
        height: "auto",
        fontSize: isMobile ? "12px" : "inherit", // Adjust font size for mobile
        padding: isMobile ? "5px" : "inherit", // Adjust padding for mobile
        overflow: "clip",
      }}
    >
      <thead>
        <tr>
          <th style={{ width: isMobile ? "50%" : "40%" }}>Item</th>
          <th>{isMobile ? "Qty" : "Quantity"}</th>
          {!isMobile && <th>Link</th>} {/* Hide Link on mobile */}
          <th>Status</th>
          {user.account_type === "NPO" && !isMobile && <th>Contact</th>}
          {props?.fulfilled && !isMobile && <th>Tracking Info</th>}
          {accepted_page_status === 4 && <th>Tracking Info</th>}
        </tr>
      </thead>
      <tbody>
        {props.data.map((item, index) => (
          <tr key={index}>
            {!isAuthenticated ||
            (user.account_type === "School" && item?.acceptance_data[0]) ||
            ((user.account_type === "NPO" || user.account_type === "member") &&
              item.status === 3) ||
            accepted_page_status === 4 ? (
              <td>
                <Stack
                  direction={"row"}
                  spacing={isMobile ? 1 : 2} // Adjust spacing for mobile
                  sx={{ alignItems: "center" }}
                >
                  <Typography
                    component={"span"}
                    level={isMobile ? "body-sm" : "body-md"}
                  >
                    {item.product_name}
                  </Typography>
                  {Object.keys(products).map((productId) => {
                    if (
                      products[productId].productName ===
                        item["product_name"] &&
                      products[productId].image_url
                    ) {
                      return (
                        <Avatar
                          key={productId}
                          src={products[productId].image_url}
                          alt={products[productId].productName}
                          sx={{
                            width: isMobile ? 24 : 40,
                            height: isMobile ? 24 : 40,
                          }} // Adjust avatar size for mobile
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </Stack>
              </td>
            ) : (
              <>
                {edit ? (
                  <td>
                    <Autocomplete
                      options={Object.keys(products).map(
                        (productId) => products[productId].productName
                      )}
                      onChange={(e, value) =>
                        setNewProduct((prev) => {
                          const newState = [...prev];
                          newState[index] = value;
                          return newState;
                        })
                      }
                      renderInput={(params) => <input {...params} />}
                      size={isMobile ? "sm" : "md"} // Adjust input size for mobile
                      sx={{ minWidth: isMobile ? 120 : 175 }} // Adjust input width for mobile
                      placeholder="Select an item"
                    />
                  </td>
                ) : (
                  <td>
                    <Stack
                      direction="row"
                      spacing={isMobile ? 1 : 2} // Adjust spacing for mobile
                      sx={{ alignItems: "center" }}
                    >
                      <Checkbox
                        color="primary"
                        size={isMobile ? "sm" : "md"} // Adjust checkbox size for mobile
                        label={item.product_name}
                        checked={
                          checked &&
                          checked[index] &&
                          checked[index].id !== null
                            ? checked[index]
                            : false
                        }
                        onChange={(e) => {
                          handleCheckBoxChange(
                            e,
                            askIndex,
                            index,
                            item,
                            item.quantity,
                            item.status,
                            item.ask_id
                          );
                        }}
                      />
                      {Object.keys(products).map((productId) => {
                        if (
                          products[productId].productName ===
                            item["product_name"] &&
                          products[productId].image_url
                        ) {
                          return (
                            <Avatar
                              key={productId}
                              src={products[productId].image_url}
                              alt={products[productId].productName}
                            />
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Stack>
                  </td>
                )}
              </>
            )}
            {/* Quantity Column */}
            {edit ? (
              <td>
                <Input
                  type="text"
                  value={newQuantity[index]}
                  onChange={(e) => {
                    setNewQuantity((prev) => {
                      const newState = [...prev];
                      newState[index] = e.target.value;
                      return newState;
                    });
                  }}
                />
              </td>
            ) : (
              <td>
                {/* {(user["account_type"] === "NPO" || user['account_type'] === "member") && */}
                {checked &&
                checked[index] &&
                checked[index].id !== null &&
                !checked[index].status ? (
                  <Input
                    placeholder={"1-" + item.remaining_quantity}
                    slotProps={{
                      input: {
                        min: 1,
                        max: item.remaining_quantity,
                        step: 1,
                      },
                    }}
                    onChange={(e) => {
                      setCheckedStates((prev) => {
                        const newState = [...prev];
                        newState[askIndex][index].requested_quantity = parseInt(
                          e.target.value
                        );
                        return newState;
                      });
                    }}
                    error={show_error_empty_quantity(index)}
                  />
                ) : (guest ||
                    user["account_type"] === "NPO" ||
                    user["account_type"] === "member") &&
                  (item["remaining_quantity"] !== item["quantity"] ||
                    item["accepted_quantity"]) ? (
                  <Typography
                    component={"span"}
                    level={isMobile ? "body-sm" : "body-md"}
                  >
                    {item.remaining_quantity
                      ? item.remaining_quantity
                      : item.accepted_quantity}
                  </Typography>
                ) : (
                  <Typography
                    component={"span"}
                    level={isMobile ? "body-sm" : "body-md"}
                  >
                    {item.quantity}
                  </Typography>
                )}
              </td>
            )}
            {/* Link to Product Column */}
            {!isMobile && (
              <td>
                {item.link === "" || item.link === null ? (
                  <Typography component={"span"} size="md">
                    N/A
                  </Typography>
                ) : (
                  <Button
                    variant="solid"
                    color="primary"
                    component="a"
                    href={item.link}
                    target="_blank"
                    sx={{ width: "auto" }}
                  >
                    Click Here
                  </Button>
                )}
              </td>
            )}
            {/* Status Column */}
            <td>
              <Typography component={"span"}>
                {show_status_button(item, user, index)}
              </Typography>
            </td>
            {/* Contact Column */}
            {user.account_type === "NPO" && !isMobile && (
              <td>
                <Typography component={"span"}>
                  {show_contact_button(item, user, index)}
                </Typography>
              </td>
            )}
            {/* Tracking Info Column */}
            {item?.tracking && user.account_type === "NPO" && !isMobile && (
              <td>
                <Button
                  variant="solid"
                  color="primary"
                  component="a"
                  href={item.tracking}
                  target="_blank"
                  sx={{ width: "auto" }}
                >
                  Click Here
                </Button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default Product;
