import { React, useState, useEffect } from "react";
import { Box, Card, IconButton, Typography, Link } from "@mui/joy/";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function LandingPage(props) {
  const user = props.user;
  const isAuthenticated = props.isAuthenticated;
  const navigate = useNavigate();
  let location = useLocation();

  function nav() {
    if (user.account_type === "School") {
      navigate(`/${user.tenant}/home`);
    } else if (user.account_type === "NPO") {
      navigate(`/${user.tenant}/home`);
    }
  }

  useEffect(() => {
    showLoggedIn();
  }, []);

  function showLoggedIn() {
    if (location && location.state && location.state.toast) {
      toast.success("Logged In Successfully!", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        toastId: "ask_created",
        onClose: () => {
          location.state.toast = false;
        },
      });
    }
  }

  return (
    <Box
      sx={{
        minHeight: 700,
        alignContent: "center",

        display: "flex",
        flexDirection: "column", // Set the flexDirection to 'row'
        gap: 2,
        overflowX: "auto", // Add this to enable horizontal scrolling if needed
        alignItems: "center", // Center the child elements vertically
      }}
    >
      <ToastContainer />
      {showLoggedIn()}
      <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
        Landing Page
      </Typography>
      <Card
        variant="outlined"
        sx={(theme) => ({
          alignContent: "center",
          gridColumn: "span 2",
          flexDirection: "row",
          flexWrap: "wrap",
          overflow: "auto",
          gap: "clamp(0px, (100% - 360px + 32px) * 999, 16px)",
          transition: "transform 0.3s, border 0.3s",
          "&:hover": {
            borderColor: theme.vars.palette.primary.outlinedHoverBorder,
            transform: "translateY(-2px)",
          },
          ":hover": {
            cursor: "pointer",
          },
          "& > *": { minWidth: "clamp(0px, (360px - 100%) * 999,100%)" },
        })}
        onClick={() => {
          navigate(`/${user.tenant}/home`);
        }}
      >
        <Typography level="title-lg">Home</Typography>
      </Card>
      <Card
        variant="outlined"
        sx={(theme) => ({
          alignContent: "center",
          gridColumn: "span 2",
          flexDirection: "row",
          flexWrap: "wrap",
          overflow: "hidden",
          gap: "clamp(0px, (100% - 360px + 32px) * 999, 16px)",
          transition: "transform 0.3s, border 0.3s",
          "&:hover": {
            borderColor: theme.vars.palette.primary.outlinedHoverBorder,
            transform: "translateY(-2px)",
          },
          ":hover": {
            cursor: "pointer",
          },
          "& > *": { minWidth: "clamp(0px, (360px - 100%) * 999,100%)" },
        })}
        onClick={() => {
          navigate(`/${user.tenant}/profile`);
        }}
      >
        <Typography level="title-lg">My Account</Typography>
      </Card>
      {user.account_type === "School" || user.account_type === "Admin" ? (
        <Card
          variant="outlined"
          sx={(theme) => ({
            alignContent: "center",
            alignItems: "center",
            gridColumn: "span 2",
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "auto",
            gap: "clamp(0px, (100% - 360px + 32px) * 999, 16px)",
            transition: "transform 0.3s, border 0.3s",
            "&:hover": {
              borderColor: theme.vars.palette.primary.outlinedHoverBorder,
              transform: "translateY(-2px)",
            },
            ":hover": {
              cursor: "pointer",
            },
            "& > *": { minWidth: "clamp(0px, (360px - 100%) * 999,100%)" },
          })}
          onClick={() => {
            navigate(`/${user.tenant}/training`);
          }}
        >
          <Typography level="title-lg">Help</Typography>
        </Card>
      ) : null}
    </Box>
  );
}
